@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.old-success-page {
  padding: 30px;
}

.success-page {
  .title {
    text-align: center;
    margin-bottom: 35px;

    .image-container {
      width: 200px;
      height: 242px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 20px;

      img {
        width: 200px;
      }
    }
  }
}

.buttons-wrapper {
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  padding-bottom: 30px;
  border-bottom: 1px solid vars.$color-gray-25;

  @include mixins.responsive('small') {
    flex-direction: column;
    margin-top: 0;
  }

  button {
    width: 300px;
    border-radius: 10px;
    padding: 8px 12px;
    border: 1px solid vars.$color-gray-200;
    justify-content: center !important;
    margin: 0 !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: vars.$color-gray-500;

    @include mixins.responsive('small') {
      width: 100%;
    }

    img {
      width: 20px !important;
      margin-right: 10px !important;
    }
  }

  a {
    color: vars.$color-primary-500;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}

button.print-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  img {
    flex-grow: 0;
    flex-shrink: 0;
    width: 2em;
    margin-right: 0.75em;
  }
}
