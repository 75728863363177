@use '../../styles/_vars.scss';

.payment-history {
  h4 {
    font-weight: bold;
  }

  table {
    width: 100%;
    text-align: center;
    border-spacing: .5em;
    margin-top: 1em;

    th,
    td {
      vertical-align: middle;
    }

    td {
      padding: .25em 0;
      width: 25%;
    }

    td[data-column-name='statusCode'] {
      border-radius: .5em;

      &[data-column-value='PAID'] {
        background: #d1eaad;
      }

      &[data-column-value='FAILURE'] {
        background: #fccfcf;
      }
    }

    tfoot td {
      text-align: center;
    }
  }
}


.payment-history-empty {
  h2 {
    margin: 1em auto;
  }

  img {
    width: 75%;
    max-width: 75vh;
  }

  text-align: center;
}

.wrapper {
  margin-top: 20px;

  a {
    margin-top: 10px;
    text-align: center;
  }

  h4 {
    font-size: 16px;
    line-height: 24px;
    color: vars.$color-primary-600;
    font-weight: 600;
  }

  .history-row {
    border-bottom: 1px solid vars.$color-gray-25;
    padding: 10px 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .info-row {
    display: flex;
    justify-content: space-between;

    &:nth-of-type(2n) {
      margin-top: 10px;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      color: vars.$color-gray-400;
    }

    .status-tag {
      padding: 3px;
      font-size: 12px;
      line-height: 18px;
      border-radius: 999px;
      width: 55px;
      text-align: center;

      &.paid-tag {
        color: #02643B;
        background: #D1FAE2;
      }

      &.failure-tag {
        color: #5A110C;
        background: #FBD3D0;
      }
    }

    .balance {
      font-weight: 600;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        line-height: 16px;
        color: vars.$color-primary-600;

        &.secondary {
          color: vars.$color-gray-400;
        }

        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
  }
}