.emptyPage {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 900;
  }

  span {
    text-transform: lowercase;
  }

  img {
    width: 75%;
    max-width: 75vh;
  }
}