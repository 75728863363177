@use '../../styles/_vars.scss';

.notice {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: vars.$color-gray-300;
  font-weight: 400;
  gap: 5px;
  margin-top: 10px;

  img {
    align-self: baseline;
    margin-top: 3px;
  }
}
