@use "../../styles/_vars.scss";
@use "../../styles/_mixins.scss";

.settlement-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 1em 0;
  }

  div {
    font-size: 1em;
    background: vars.$color-secondary-c;
    color: vars.$color-bg;
    border-radius: 1em;
    padding: .5em 1em;
  }

  @include mixins.responsive('small') {
    p {
      margin-top: 0;
    }

    display: block;
    text-align: center;
  }
}
