@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.wrapper {
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100%;
  max-height: 100vh;
  background: #f2f8ff;

  @include mixins.responsive('medium') {
    display: block;
  }

  .left-panel {
    padding: 80px;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;

    @include mixins.responsive('large') {
      padding: 50px;
    }

    @include mixins.responsive('medium') {
      display: none;
    }

    h1 {
      color: vars.$color-primary-600;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 50px;
      line-height: 44px;
    }

    .logo {
      margin-bottom: 20px;
    }

    .left-panel-img-class {
      width: fit-content;
      display: block;
      margin: auto 0;

      @include mixins.responsive('large') {
        width: 100%;
      }

      @include mixins.responsive('medium') {
        display: none;
      }
    }
  }

  .right-panel {
    background: white;
    padding: 80px 126px;
    margin: 0 auto;
    border-radius: 24px 0 0 24px;

    @include mixins.responsive('large') {
      padding: 50px;
    }

    @include mixins.responsive('medium') {
      width: 100%;
      height: 100%;
    }

    @include mixins.responsive('small') {
      padding: 20px;
    }

    .logo-wrapper {
      display: block;
      margin-bottom: 15px;

      @include mixins.responsive('medium') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .link {
        display: block;

        @media (min-width: 769px) {
          display: none;
        }
      }

      .logo {
        display: block;

        @media (min-width: 769px) {
          display: none;
        }

        @include mixins.responsive('small') {
          display: none;
        }

        @include mixins.responsive('medium') {
          margin-bottom: 0;
        }
      }
    }

    .logo {
      width: 200px;
      margin-bottom: 20px;
    }

    .logo-small {
      display: none;

      @include mixins.responsive('small') {
        display: block;
      }
    }

    h2 {
      color: vars.$color-primary-600;
      font-size: 42px;
      font-weight: 600;
      line-height: 60px;

      @include mixins.responsive('small') {
        font-size: 36px;
        line-height: 38px;
      }
    }
  }
}
