@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.custom-dropdown {
  .dropdown-date {
    padding: 8px 10px 8px 10px;
    border: 1px solid vars.$color-gray-25;
    border-radius: 10px;
    color: vars.$color-gray-500;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  table {
    border-collapse: separate;
    border-spacing: 5px;
    position: absolute;
    background: white;
    padding: 10px 20px;
    margin-top: 5px;
    border: 1px solid vars.$color-gray-25;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    z-index: 1;


    @include mixins.responsive('small') {
      padding: 10px;

      th, td {
        font-size: 12px !important;
      }
    }
  }
}
