@use '../../styles/_vars.scss';

.expander {
  border: 1px solid vars.$color-gray-25;
  padding: 16px 24px;
  border-radius: 12px;
  margin-top: 30px;
  background: white;

  .expanderLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: vars.$color-primary-600;

    svg {
      transform: rotate(180deg);
      transition: transform 0.2s linear;
    }

    &[data-expanded='true'] {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid vars.$color-gray-25;

      svg {
        transform: rotate(360deg);
      }
    }
  }
}
