@use "../../styles/_vars.scss";

$duration: .1s;

.toggle {
  display: inline-block;
  background: rgba(vars.$color-secondary-a, .25);
  border-radius: 1.5rem;
  padding: .25em;
  font-size: 1.125em;
  user-select: none;
  box-shadow: inset 0 .125em .25em vars.$color-shadow;

  & > input[type=radio] {
    display: none;

    &:checked + .toggle-item {
      background: vars.$color-secondary-a;
      color: vars.$color-bg;
      box-shadow: 0 .0625em .0625em vars.$color-shadow;
    }
  }

  &-item {
    display: inline-block;
    padding: .5em 1em;
    transition: color $duration, background $duration, box-shadow $duration;
    border-radius: 1.5rem;
    color: vars.$color-primary-b;
    font-weight: bold;
    cursor: pointer;
  }
}
