@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.account-details {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th, td {
    font-size: 16px;
    line-height: 24px;
    color: vars.$color-gray-400;
    font-weight: 400;
    vertical-align: middle;
  }

  th {
    text-align: left;
  }

  td {
    text-align: right;
    font-weight: 600;
  }
}
