@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.resumed-payment {
  text-align: left;
  text-shadow: none;

  h4, h5 {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: vars.$color-gray-500;
  }

  h5 {
    margin-top: 10px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: vars.$color-primary-600;

    span {
      font-size: 10px;
    }
  }

  .information-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  .with-tooltip {
    background: vars.$color-gray-700;
    color: white;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    white-space: pre-wrap;
    padding: 12px;
    border-radius: 12px;
    z-index: 999999;

    @include mixins.responsive('small') {
      max-width: 250px;
      white-space: normal;
    }
  }

  .tooltip-print {
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: vars.$color-gray-400;
  }

  .number-payments {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: vars.$color-gray-300;
  }
}
