@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.boxes-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 20px;
  margin: 30px 0;

  @include mixins.responsive('small') {
    display: block;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .frequency-box {
    background-color: white;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid vars.$color-gray-25;
    border-radius: 12px;
    padding: 16px;

    @include mixins.responsive('small') {
      width: 100%;
    }
  }

  .select-button {
    padding: 8px 10px 8px 10px;
    font-size: 12px;
    line-height: 18px;
    margin-top: 15px;
  }
}
