@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.table-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: vars.$color-primary-600;
  border: 1px solid vars.$color-gray-25;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  h2 {
    color: vars.$color-primary-600;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th,
    td {
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: vars.$color-gray-400;
      vertical-align: middle;
    }

    th {
      text-align: left;
      font-weight: 400;
    }

    td {
      text-align: right;
      font-weight: 600;
    }
  }
}
