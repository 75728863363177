@use "../../styles/vars";
@use "../../styles/mixins";

.navbar-overlay {
  background: none;
  height: 100%;
  max-width: vars.$width-navbar;
}

.backdrop {
  display: none;
}

.reference-tag {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.info-row {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;

  span {
    margin: 0;
    text-align: center;
  }

  &.user-info {
    margin-bottom: 30px;
    margin-top: 20px;

    h6 {
      color: vars.$color-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.navbar-container {
  display: block;
  width: 280px;
  background: vars.$color-bg;
  overflow-y: auto;
  height: 100%;
  border-right: 1px solid #EAECF0;
  padding: 10px 20px;
  position: relative;

  a {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    position: relative;
    margin: 1em 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: vars.$color-gray-500;
    padding: 10px 0;

    &[data-active-route] {
      border-radius: 6px;
      background: vars.$color-primary-25;
      color: vars.$color-primary-500;

      img {
        path {
          fill: vars.$color-primary-500;
        }
      }
    }

    span {
      margin: 0;
      text-align: center;
    }
  }
}

.navbar-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.navbar-full-name,
.navbar-reference-number {
  color: vars.$color-fg-alt;
}

.logo {
  margin-right: 15px;
}

.navbar-logout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;

  a {
    display: flex;
    margin: 15px;
    padding-top: 15px;
    border-top: 1px solid #EAECF0;
    justify-content: space-between;

    span {
      margin: 0;
    }
  }
}

@include mixins.responsive('medium') {
  .navbar-overlay {
    display: none;
    position: fixed;
    z-index: 1;
    height: calc(100% - vars.$height-header);
    top: vars.$height-header;
  }

  .backdrop {
    display: none;
    width: calc(100% - vars.$width-navbar);
    height: calc(100% - vars.$height-header);
    position: fixed;
    top: 80px;
    background: #10182833;
    right: 0;
    z-index: 1;
  }

  .toggled {
    display: block;
  }

  :global #toggle-nav:checked + div {
    display: block;
  }
}

@include mixins.responsive('small') {
  .navbar-overlay {
    width: 100%;
    max-width: 100%;
  }

  .navbar-container {
    width: 100%;
  }

  .backdrop {
    display: none;
  }
}
