@use "mixins";

.split {
  display: flex;
  flex-wrap: wrap;

  & > * {
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}

@mixin full-width {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.split-small > * {
  @include mixins.responsive('small') {
    @include full-width;
  }
}

.split-medium > * {
  @include mixins.responsive('medium') {
    @include full-width;
  }
}

.split-large > * {
  @include mixins.responsive('large') {
    @include full-width;
  }
}
