$breakpoints: (
  'tiny': 320px,
  'small': 600px,
  'medium': 768px,
  'large': 1024px,
  'xlarge': 1200px,
) !default;

$color-fg: #1a202c;
$color-fg-alt: #778;
$color-bg: #fff;
$color-body: #f8f8ff;

$color-primary-a: #1d355a;
$color-primary-b: #578f0c;
$color-primary-c: #fff;

$color-secondary-a: #72ad0d;
$color-secondary-b: #ecf4ff;
$color-secondary-c: #25466d;
$color-secondary-d: #0c1624;
$color-secondary-e: #272727;
$color-secondary-f: #d70004;
$color-secondary-g: #e6f5fe;
$color-secondary-h: #92bf45;

$color-disabled: #a0b5c0;
$color-active: #e0e5eb;
$color-error: #be1a0e;
$color-error-400: #f51a0a;
$color-error-300: #fb4437;
$color-glow: #006eb7;
$color-shadow: rgba(0, 0, 24, 0.3);
$color-input-shadow: #1018280d;

$color-button-primary: #0563c7;

$height-header: 80px;
$width-navbar: 280px;
$border-radius-section: 0.75rem;

$font-75-opacity: rgba(0, 0, 0, 0.75);
$font-50-opacity: rgba(0, 0, 0, 0.5);

$color-white: #ffffff;
$color-black: #000000;
$color-login-background: #f2f8ff;
$color-background-pages: #fbfdff;

$color-primary-25: #e5f2ff;
$color-primary-50: #cce9ff;
$color-primary-100: #99ceff;
$color-primary-200: #66bdff;
$color-primary-300: #33a7ff;
$color-primary-400: #038cfc;
$color-primary-500: #0563c7;
$color-primary-600: #0a418f;
$color-primary-700: #0a285c;
$color-primary-800: #06132d;
$color-primary-900: #020409;

$color-secondary-25: #e8fcf0;
$color-secondary-50: #d1fae2;
$color-secondary-100: #a3f5be;
$color-secondary-200: #77eea7;
$color-secondary-300: #4ee494;
$color-secondary-400: #2bd490;
$color-secondary-500: #12ba6c;
$color-secondary-600: #039654;
$color-secondary-700: #02643b;
$color-secondary-800: #03301d;
$color-secondary-900: #010a06;

$color-gray-25: #f0f0f5;
$color-gray-50: #e0e5eb;
$color-gray-100: #c0cad8;
$color-gray-200: #a5aec0;
$color-gray-300: #8995a9;
$color-gray-400: #6c7a93;
$color-gray-500: #596173;
$color-gray-600: #3f4a5a;
$color-gray-700: #27303f;
$color-gray-800: #111822;
$color-gray-900: #030407;

$color-error-25: #ffebe5;
$color-error-50: #fbd3d0;
$color-error-100: #fba39d;
$color-error-200: #fc7069;
$color-error-300: #fb4437;
$color-error-400: #f51a0a;
$color-error-500: #be1a0e;
$color-error-600: #851b14;
$color-error-700: #5a110c;
$color-error-800: #2c0a07;
$color-error-900: #080302;

$color-warning-25: #fff7e5;
$color-warning-50: #fff2cc;
$color-warning-100: #fde49b;
$color-warning-200: #fdd568;
$color-warning-300: #fec134;
$color-warning-400: #fca503;
$color-warning-500: #c67306;
$color-warning-600: #974802;
$color-warning-700: #622604;
$color-warning-800: #2f1404;
$color-warning-900: #090301;
