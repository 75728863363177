.account-suppressed {
  text-align: center;

  button {
    margin: 2rem auto;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  & > a {
    font-size: 1.25em;
  }
}
