@use "../../styles/vars";
@use "../../styles/mixins";

header {
  position: fixed;
  z-index: 100000;
  top: 0;
  background: vars.$color-bg;
  width: 100%;
  height: 3em;
  box-shadow: 0 0 1em vars.$color-shadow;
  @include mixins.hide-in-print;
}

.container {
  height: 100%;
}

.header-logo {
  position: absolute;
  left: 1em;
  top: .125em;
  height: 2.5em;

  &:hover {
    opacity: .6;
  }
}

.mobile-nav-toggle {
  display: none;
}

.dropdowns {
  position: absolute;
  top: .75rem;
  right: 0;

  & > * {
    margin-right: .5rem;
  }

  @include mixins.responsive('small') {
    font-size: 1.125em;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: vars.$width-navbar auto;
  height: calc(100% - vars.$height-header);

  @include mixins.responsive('medium') {
    display: block;
  }
}

@include mixins.responsive('medium') {
  .mobile-nav-toggle {
    display: block;
    
    label {
      padding: 0 .5em 0 .75em;
      font-size: 2rem;

      &:hover {
        opacity: .6
      }
    }

    a {
      position: relative;
      top: .375em;
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  .header-logo {
    display: none;
  }

  .content-section-nav {
    margin-left: 0;
  }
}
