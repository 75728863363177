@use "../../styles/_mixins.scss";

.error-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 80rem;

  & > img {
    width: 45%;
  }

  & > div {
    font-size: 1.25em;
    width: 50%;
    margin-left: 5%;

    & > * {
      margin-right: 2.5%;
    }

    h1 {
      font-size: 4em;
    }

    h2 {
      font-size: 1.5em;
    }
  }

  a {
    display: block;
    margin: .5em auto auto auto;
    text-align: center;
  }

  @include mixins.responsive('medium') {
    & > img {
      margin: auto;
      width: 75%;
      min-width: 10em;
    }

    & > div {
      font-size: 1.25em;
      width: 100%;
      text-align: center;
    }
  }

  @include mixins.responsive('tiny') {
    & > div {
      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.25em;
      }

      font-size: 1em;
    }

    a {
      font-size: 1em;
    }
  }
}
