@use '../../styles/_mixins.scss';

.wrapper { 
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -20px);
  background: #D1FAE2;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
  gap: 10px;
  width: max-content;

  @include mixins.responsive('small') {
    left: 0;
    transform: translate(0, -20px);
    margin: 0 20px;
    width: auto
  }
};
