.login-form form {
    display: flex;
    flex-direction: column;
}

.user-messages {
    text-align: center;
    margin-bottom: 0.5em;
}

.user-messages span {
    color: red;
}