@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.progress-box {
  padding: 20px;
  border: 1px solid vars.$color-gray-25;
  border-radius: 12px;
  height: fit-content;
  background: white;

  .svg-container {
    position: relative;
    display: flex;
    justify-content: center;

    .loader-info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      width: 200px;

      &.with-text {
        padding-top: 28px;
      }

      p {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: vars.$color-gray-400;
      }

      .payments-made {
        font-size: 48px;
        line-height: 60px;
        font-weight: 600;
        color: vars.$color-primary-500;
      }
    }
  }

  .start-plan {
    font-size: 20px;
    line-height: 30px;
    color: vars.$color-primary-600;
    text-align: center;
    font-weight: bold;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 14px;
      line-height: 20px;
      color: vars.$color-gray-400;
    }

    .balance {
      font-weight: 600;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        color: vars.$color-primary-500;

        &.secondary {
          color: vars.$color-gray-400;
        }

        h5 {
          margin-left: 5px;
          font-size: 24px;
          line-height: 32px;
        }

        h6 {
          margin-left: 5px;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  button {
    font-size: 14px;
    line-height: 20px;   
    margin-top: 15px;
  }
}

.shown-mobile {
  display: none;

  @include mixins.responsive('medium') {
    display: block;
    margin-top: 0;
  }
}
