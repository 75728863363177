@use '../../styles/vars';
@use '../../styles/mixins';

.payment-info {
  font-size: 16px;
  line-height: 24px;
  color: vars.$color-gray-400;
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;

  th,
  td {
    padding: 8px 0;
    vertical-align: middle;
  }

  th {
    text-align: left;
    font-weight: 400;
  }

  td {
    text-align: right;
  }

  .payment-method-row {
    th,
    td {
      padding-bottom: 15px;
    }
  }

  .total-balance-row {
    th,
    td {
      border-top: 1px solid vars.$color-gray-25;
      padding-top: 15px;
    }

    &.border-bottom {
      th,
      td {
        border-bottom: 1px solid vars.$color-gray-25;
        padding-bottom: 15px;
      }
    }
  }

  ol {
    display: block;
    margin-left: 2em;
    font-size: 0.875em;

    @include mixins.responsive_columns('large', 2);

    li {
      &::marker {
        font-weight: bold;
        color: vars.$color-secondary-c;
      }
      text-align: left;
      padding: 0.5em 0 0.5em 0.5em;
    }
  }
}
