@use '../../styles/_mixins.scss';

.payment-plan-page {
  padding: 30px;

  .without-gap {
    gap: 0;
  }

  .custom-datepicker {
    @include mixins.responsive('small') {
      padding: 10px;

      th, td {
        font-size: 12px;
      }
    }
  }
}

.payment-date {
  text-align: center;

  table {
    margin: 1em auto 0 auto;
  }

  h3 {
    margin-top: 0;
  }

  .day-dropdown {
    text-align: center;
    max-width: 100%;
    margin-top: 1em;
  }
}

.payment-method-form {
  div[data-tab-group] section {
    display: flex;
    justify-content: center;
  }
}
