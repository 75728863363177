@use "../../styles/vars";
@use "../../styles/mixins";

.multiple-choice {
  display: flex;
  flex-wrap: wrap;

  input {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
  }

  label {
    border-radius: .5em;
    box-shadow: 0em .125em .25em vars.$color-shadow;
    padding: .5em 1em;
    margin: .5em;
    border: .125em solid rgba(0, 0, 0, 0);
    background: vars.$color-bg;
  }

  input:checked + label {
    border: .125em solid vars.$color-secondary-c;
    box-shadow: 0 0 .25em vars.$color-glow;
  }
}