@use '../../styles/_vars.scss';
@use '../../styles/mixins';

.language-dropdown {
  color: vars.$color-button-primary !important;
  border-radius: 6px;
  display: block !important;
  padding: 0 !important;

  @include mixins.responsive('small') {
    background-color: vars.$color-primary-25 !important;
    padding: 8px 12px !important;
  }

  label {
    a {
      color: vars.$color-button-primary;
      font-weight: bold;
    }
  }
}
