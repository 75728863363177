@use '../../styles/_vars.scss';

.dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  user-select: none;

  a {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    &:hover {
      text-decoration: none;
    }
  }

  .dropdown-label {
    color: vars.$color-primary-600;
  }

  label {
    position: relative;
    display: block;
  }

  &.outline {
    border: .0125em solid vars.$color-fg;
    border-radius: 0.5em;
    padding: .25em .5em;
  }

  &[data-is-open=true] {
    label::after {
      transform: rotate(90deg);
    }

    ul {
      visibility: visible;
      z-index: 10000;
    }
  }

  ul {
    visibility: hidden;
    position: absolute;
    overflow-y: scroll;
    top: 100%;
    right: 0;
    margin-top: 10px;
    background: vars.$color-bg;
    border-radius: 10px;
    box-shadow: 0px 12px 16px -4px #10182814;

    &.full {
      width: 100%;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: vars.$color-gray-500;
      padding: 0.75em 1em;
    }

    &.compact {
      padding: .25em .25em;
      margin-top: .25em;
      left: 0;
      right: unset;

      li {
        padding: .5em .5em;
        border-radius: 0;
        white-space: nowrap;
  
        &:not(:first-child) {
          border-top: 1px solid vars.$color-disabled;
        }
      }
    }

    li {
      list-style-type: none;
      padding: .75em .75em;

      a {
        color: vars.$color-gray-500;
      }

      &:not(:first-child) {
        border-top: 1px solid #F0F0F5;
      }
    }
  }

  label,
  ul li {
    cursor: pointer;
  }
}
