@use '../../styles/_vars.scss';

$button-base-color: vars.$color-secondary-a;
$button-error-color: vars.$color-secondary-f;
$button-disabled-color: vars.$color-disabled;
$button-alternate-color: vars.$color-secondary-c;
$button-disclosure: vars.$color-secondary-400;

.button-base {
  font-family: inherit;
  display: inline-block;
  position: relative;
  border-radius: 1em;
  padding: 0.5em 1em;
  color: vars.$color-bg;
  background: $button-base-color;
  border-color: $button-base-color;
  font-weight: bold;
  cursor: pointer;

  &:not(.button-outline) {
    box-shadow: 0 0.125rem 0.0625rem vars.$color-shadow;
  }

  &,
  &:hover {
    text-decoration: none;
  }

  &:not(:disabled) {
    &:hover {
      opacity: 0.85;
    }
    &:active {
      top: 0.0625rem;
      box-shadow: none;
    }
  }
  &:disabled {
    cursor: default;
    background: $button-disabled-color;
    border-color: $button-disabled-color;
  }
}

.transparent {
  background: none;
  border: none;
  box-shadow: none !important;
  text-shadow: none !important;
}

.button-small {
  font-size: 0.875em;
}

.button-large {
  font-size: 1.5em;
}

.button-full {
  display: block;
  width: 100%;
}

.button-error {
  background: $button-error-color;
  border-color: $button-error-color;
}

.button-alternate {
  background: $button-alternate-color;
  border-color: $button-alternate-color;
}

.button-outline {
  background: transparent;
  border-width: 0.125em;
  border-style: solid;

  &.button-base {
    color: $button-base-color;
  }
  &.button-error {
    color: $button-error-color;
  }
  &.button-alternate {
    color: $button-alternate-color;
  }
  &:disabled {
    color: $button-disabled-color;
  }
}

.button-loading {
  background: $button-disabled-color;
  cursor: default;
}

.button-secondary {
  border: 1px solid vars.$color-gray-200;
  border-radius: 10px;
  box-shadow: none !important;
  background: white;
  font-size: 14px;
  line-height: 20px;
  color: vars.$color-gray-500;
}

.button-primary {
  background: vars.$color-button-primary;
  line-height: 24px;
  font-size: 16px;
  box-shadow: none !important;
  border-radius: 10px;
}

.button-disclosure {
  border-radius: 0.25em;
  font-size: 16px;
  padding: 12px 18px;
  background: $button-disclosure;
  border-color: $button-disclosure;
  font-weight: 600;
  box-shadow: none !important;
}
