@use '../../styles/vars';
@use '../../styles/_mixins.scss';

.disclosures {
  background: vars.$color-login-background;
  padding-bottom: 28px;

  @include mixins.responsive('medium') {
    padding-bottom: 0;
  }

  .disclosures-content {
    padding: 40px;
    position: relative;

    @include mixins.responsive('medium') {
      padding: 0;
    }

    .card {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      background: vars.$color-bg;
      border-radius: 12px;

      @include mixins.responsive('medium') {
        border-radius: 0;
      }

      .card-header {
        background: vars.$color-primary-700;
        height: 56px;
        border-radius: 12px 12px 0 0;
        @include mixins.responsive('medium') {
          display: none;
          border-radius: 0;
        }
      }

      .card-body {
        border-radius: 0 0 12px 12px;
        padding: 40px 102px 60px 102px;

        @include mixins.responsive('medium') {
          padding: 40px 72px 60px 72px;
          border-radius: 0;
        }

        @include mixins.responsive('small') {
          padding: 32px 24px 60px 24px;
        }

        h1 {
          font-size: 1.875rem;
          color: vars.$color-primary-600;
          margin-bottom: 0;
        }

        .disclosures-list {
          display: flex;
          flex-direction: column;
          margin-bottom: 3rem;
          gap: 0;

          h3 {
            font-weight: bold;
            color: vars.$color-primary-600;
            margin-top: 1rem;

            @include mixins.responsive('medium') {
              font-size: 1.25rem;
            }

            @include mixins.responsive('small') {
              font-size: 1.125rem;
            }
          }

          p {
            color: vars.$color-gray-600;
          }

          .description {
            color: vars.$color-gray-400;
            font-size: 0.875rem;
          }
        }

        .button-container {
          margin-top: 1rem;
          position: sticky;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem 0 2rem;
          background: vars.$color-white;

          &::before {
            content: '';
            position: absolute;
            top: -3rem;
            left: 0;
            right: 0;
            height: 3rem;
            background: linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }
        }
      }
    }
  }
}
