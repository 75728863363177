@use "../../styles/_vars.scss";
@use "../../styles/_mixins.scss";

.payment-form {
  flex-wrap: nowrap !important;
  flex-direction: row;

  @include mixins.responsive('small') {
    flex-direction: column;
  }

  label {
    width: 50%;
    flex-basis: 50%;
    padding: 0 !important;

    @include mixins.responsive('small') {
      width: 100%;
    }

    section {
      margin: 0;
      border-radius: 12px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      height: 50px;
      box-shadow: 0px 1px 2px 0px #1018280D;
      border: 1px solid vars.$color-gray-25;
      justify-content: space-around;

      @include mixins.responsive('small') {
        justify-content: flex-start;
      }

      img {
        margin-right: 5px !important;
      }

      p {
        font-size: 16px !important;
        line-height: 24px;
        font-weight: 600;
        color: vars.$color-primary-600;
        margin: 0;
      }
    }
  }
}

.wrapper-class {
  iframe {
    max-height: 1200px;
  }
}

.information-wrapper {
  padding: 40px;
  text-align: center;

  @include mixins.responsive('small') { 
    padding: 40px 0;
  }

  h2 {
    color: vars.$color-gray-400;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }

  .input-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    border: 1px solid vars.$color-gray-25;
    border-radius: 12px;
    padding: 16px;

    @include mixins.responsive('small') {
      grid-template-columns: 1fr;
      gap: 20px;
    }
    
    button {
      width: 100%;
      justify-content: space-between;
    }
  };
}

.toggle-wrapper {
  display: flex;
  justify-content: center;
  
  .toggle {
    display: flex;
    width: fit-content;
    background: vars.$color-primary-25;
    border-radius: 60px;
    padding: 2px;
    font-size: 1.125em;
    user-select: none;
    margin-bottom: 20px;

    @include mixins.responsive('small') {
      width: 100%;
    }
  
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px 24px;
      transition: color .1s, background .1s, box-shadow .1s;
      border-radius: 1.5rem;
      color: vars.$color-primary-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      cursor: pointer;

      @include mixins.responsive('small') {
        width: 50%;
        padding: 8px;
        font-size: 14px;
      }
  
      &.active {
        background: vars.$color-primary-500;
        color: white;
      }
    }
  }
}
