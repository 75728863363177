@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.dot-list {
  gap: 10px;
}

.dot, .dot-active {
  width: 12px;
  height: 12px;

  button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.dot button {
  background: vars.$color-gray-100;
}

.dot-active button {
  background: vars.$color-primary-500;
}

.carousel-item {
  transform: scale(0.96);
}
