@use "../../styles/vars";

.input-element {
  display: inline-block;
  border: 1px solid vars.$color-primary-a;
  border-radius: .5em;
  padding: 0 .75em;
  font-size: 1em;
  height: 2.5em;
  box-shadow: inset 0 .125em .25em vars.$color-shadow;

  &::placeholder {
    color: vars.$color-disabled;
  }
}

.input-element {
  width: 100%;
}

.error-border {
  border: 1px solid vars.$color-error-300 !important;
}

.input-label {
  display: block;
  position: relative;
  margin: 1em 0 .25em 0;
}

.input-tooltip {
  background: none;
  border: 1px solid vars.$color-fg;
  border-radius: 1em;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  right: 0;
  top: -0.25em;
}

.input-error {
  color: vars.$color-error-400;
}

.input-required {
  color: vars.$color-error;
  margin-left: 5px;
}
