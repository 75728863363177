@use '../../styles/_vars.scss';

.go-back {
  display: inline-block;
  margin: .5em 0;
  font-size: 1.25em;
  color: vars.$color-fg;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &::before {
    content: '⬅';
    font-size: .875em;
    font-weight: bold;
    padding-right: .375em;
  }
}
