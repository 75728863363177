@use "../../styles/_vars.scss";
@use "../../styles/_mixins.scss";

.offer-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: vars.$color-secondary-c;
  background: linear-gradient(vars.$color-secondary-c 10%, vars.$color-glow 250%);
  color: #fff;
  border-radius: vars.$border-radius-section vars.$border-radius-section 0 0;
  box-shadow: 0 .125em .125em vars.$color-shadow;
  text-align: left;

  &-desc {
    color: vars.$color-secondary-b;

    @include mixins.responsive('tiny') {
      font-size: .875em;
    }
  }

  p {
    color: white;
  }

  & > div {
    padding: .75rem .25em;

    &:first-child {
      padding-left: 1.5rem;
    }

    &:last-child {
      padding-right: 1.5rem;
    }

    p {
      &:first-child {
        margin-bottom: .125em;
      }

      margin: 0;
    }
  }
}
