@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.detail-box {
  border-radius: 12px;
  padding: 16px;
  border: 1px solid vars.$color-gray-25;
  height: fit-content;

  .wrapper {
    padding-bottom: 10px;
  }

  h2 {
    color: vars.$color-primary-600;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }

  .confirm-info {
    h5 {
      color: vars.$color-gray-400;
      font-size: 12px;
      line-height: 18px;

      &.align-right {
        text-align: right;
      }
    }

    .number-payments {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: vars.$color-gray-300;
    }

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 18px;

      strong {
        font-size: 20px;
        line-height: 30px;
        margin-left: 5px;
      }
    }
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h5 {
      color: vars.$color-gray-400;
      font-size: 12px;
      line-height: 18px;

      &.align-right {
        text-align: right;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: vars.$color-primary-600;
  
      span {
        font-size: 10px;
      }
    }

    .plan-type-tag {
      position: absolute;
      right: 0;
      padding: 2px 8px;
      border-radius: 10px;
      color: vars.$color-primary-500;
      background: vars.$color-primary-25;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      width: fit-content;
    }

    .balance {
      color: vars.$color-primary-500;
      font-weight: 600;

      &.greyed {
        color: vars.$color-gray-500;

        strong {
          font-size: 16px;
          line-height: 24px;
        }
      }

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 18px;

        strong {
          font-size: 20px;
          line-height: 30px;
          margin-left: 5px;
        }
      }
    }

    &.separator {
      border-bottom: 1px solid vars.$color-gray-25;
      padding-bottom: 10px;
    }

    &.mobile-display {
      display: none;
    
      @include mixins.responsive('small') {
        display: flex;
      }
    }

    &.web-display {
      @include mixins.responsive('small') {
        display: none;
      }
    }
  }
}
