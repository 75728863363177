@use "../../styles/vars";
@use "../../styles/mixins";

.payment-info {
  font-size: 1.25em;
  width: 100%;

  th, td {
    padding: .25em 0;
  }

  th {
    text-align: left;

    img {
      max-width: 1.5em;
      margin-right: .5em;
    }
  }

  td {
    text-align: right;

    img {
      max-width: 1.25em;
      margin-right: 0.375em;
    }
  }

  ol {
    display: block;
    margin-left: 2em;
    font-size: .875em;

    @include mixins.responsive_columns('large', 2);

    li {
      &::marker {
        font-weight: bold;
        color: vars.$color-secondary-c;
      }
      text-align: left;
      padding: .5em 0 .5em .5em;
    }
  }
}