@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

$square-size: 2.5em;

.datepicker {
  tr {
    th {
      color: vars.$color-primary-600;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &.big-title {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  th, td {
    text-align: center;
    width: $square-size;
    height: $square-size;
    min-width: $square-size;
    min-height: $square-size;
    max-width: $square-size;
    max-height: $square-size;
    overflow: visible;
    vertical-align: middle;
    user-select: none;
    color: vars.$color-gray-600;
    font-weight: 400;
  }

  button, td {
    cursor: pointer;
  }

  .selected {
    background: #12BA6C;
    border-radius: 70px;
    color: white;
    font-weight: 600;
  }

  .disabled {
    color: vars.$color-gray-300;
    cursor: default;
  }

  button {
    background: transparent;
    position: relative;

    &:active {
      top: 0.05rem;
    }
  }

  .month-arrow {
    vertical-align: bottom;
  }
}
