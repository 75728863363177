@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.no-scroll {
  overflow: hidden;
  display: none;
}

.pplp {
  background: #F1F8FF;
  padding: 30px 0;

  @include mixins.responsive('medium') {
    padding: 0;
  }

  .plan-tag {
    padding: 4px 10px 4px 12px;
    border-radius: 10px;
    color: vars.$color-primary-500;
    background: vars.$color-primary-25;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    width: fit-content;
    margin-bottom: 15px;
  }

  .payment-date-picker {
    border: 1px solid vars.$color-gray-25;
    border-radius: 12px;
    padding: 16px;
    margin: 30px 0;
    display: grid;
    grid-template-columns: 1fr min-content;

    @include mixins.responsive('small') {
      display: flex;
      flex-direction: column-reverse;

      .right-info {
        border-bottom: 1px solid vars.$color-gray-25;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    &.reversed {
      grid-template-columns: auto auto;

      @include mixins.responsive('small') {
        flex-direction: column;
      }

      .left-info {
        border-right: none;
        margin: 0;
        margin-left: 10px;
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        gap: 5px;

        &.without-input {
          margin-left: 20px;
          grid-template-columns: auto minmax(auto, 200px);

          @include mixins.responsive('small') {
            margin-left: 0;
            grid-template-columns: auto;
          }
        }

        @include mixins.responsive('small') {
          grid-template-columns: auto;
          margin-left: 0;
          gap: 20px;
        }

        input {
          width: 65px;
          padding: 8px 12px;
          border: 1px solid vars.$color-gray-25;

          @include mixins.responsive('small') {
            width: 100%;
          }

          &.error {
            border: 1px solid vars.$color-error-400;
          }
        }

        .amount-error {
          grid-column: 1/4;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: vars.$color-error-400;

          @include mixins.responsive('small') {
            grid-column: auto;
          }
        }
      }

      .right-info {
        border-right: 1px solid vars.$color-gray-25;
        padding-right: 10px;

        @include mixins.responsive('small') {
          border-right: none;
          padding-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    .left-info {
      border-right: 1px solid vars.$color-gray-25;
      margin-right: 20px;

      p {
        margin-bottom: 10px;
      }

      @include mixins.responsive('small') {
        border-right: none;
        margin-right: 0;

        button {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    .right-info {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        color: vars.$color-gray-400;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
      }

      h1 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: vars.$color-primary-500;

        span {
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;
          margin-right: 4px;
        }
      }
    }

    p {
      margin-top: 0;
      color: vars.$color-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  .payment-plan-box {
    border-radius: 0px 0px 12px 12px;
    margin: 0 auto;
    width: 800px;
    height: fit-content;
    background: white;
    padding: 40px 102px;
    position: relative;
    margin-top: 60px;

    @include mixins.responsive('medium') {
      width: 100%;
      padding: 40px 60px;
    }

    @include mixins.responsive('small') {
      padding: 40px 20px;
      padding-top: 0;
    }

    h1 {
      font-size: 30px;
      line-height: 38px;
      font-weight: 600;
      color: vars.$color-primary-600;
      width: fit-content;

      &.centered {
        margin: auto;
        margin-bottom: 1rem;
        text-align: center;
      }
    }

    .legal {
      .credit-disclosure {
        background: #F2F8FF;
        padding: 24px;
        border-radius: 12px;
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
      }

      p, a, .credit-disclosure {
        color: vars.$color-gray-300;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      a {
        font-weight: 600;
        text-decoration: underline;
      }

      div {
        margin-bottom: 25px;
      }

      .disclosure-border {
        border-top: 1px solid vars.$color-gray-25;
        padding-top: 25px;
      }
    }

    .stepper {
      width: 100%;
      height: 50px;
      position: absolute;
      background: vars.$color-primary-700;
      left: 0;
      top: -60px;
      border-radius: 12px 12px 0px 0px;
      padding: 8px 24px 8px 24px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }

      @include mixins.responsive('medium') {
        background: white;
        height: 60px;
        padding: 0 60px;
      }

      @include mixins.responsive('small') {
        padding: 0 20px;
      }

      &.has-back-arrow {
        display: grid;
        grid-template-columns: 5% 95%;

        button {
          padding: 10px;
        }
      }
    }

    .plan-box-wrapper {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(2, calc(50% - 7.5px));
      padding-bottom: 30px;
      position: relative;
      border-bottom: 1px solid vars.$color-gray-25;

      @include mixins.responsive('small') {
        margin-bottom: 20px;
        border-bottom: none;
        padding-bottom: 20px;
        display: block;
      }

      .plan-box {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid vars.$color-gray-25;
        padding: 32px 24px 40px 24px;
        position: relative;

        &.most-offered {
          border: 2.5px solid vars.$color-primary-500;
          overflow: hidden;
          height: 100%;

          .tag {
            position: absolute;
            top: 8px;
            right: -30px;
            transform: rotate(40deg);
            background: #0563C7;
            color: white;
            padding: 5px 32px;
            font-size: 8px;
            line-height: 16px;
            font-weight: 600;
          }
        }

        .primary-text {
          color: vars.$color-gray-400;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          div {
            margin-top: 10px;
            color: vars.$color-primary-500;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 20px;
            line-height: 30px;
            border-bottom: 1px solid vars.$color-gray-25;
            padding-bottom: 15px;

            span {
              font-size: 36px;
              line-height: 44px;
              font-weight: 600;
            }
          }
        }

        .secondary-text {
          color: vars.$color-gray-400;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin: 15px 0;

          &::first-letter {
            text-transform: capitalize;
          }

          .primary-button {
            margin-top: 15px;
            width: 100%;
          }
        }

        .message {
          font-size: 10px;
          line-height: 16px;
          color: vars.$color-gray-300;
          margin-bottom: 0;
          margin-top: 15px;
          font-weight: 400;
        }

        button {
          padding: 8px 10px 8px 10px;
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px #1018280D;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          text-shadow: none;

          &.primary-button {
            background: vars.$color-primary-500;
            color: white;
          }
  
          &.secondary-button {
            background: white;
            color: vars.$color-gray-500;
            border: 1px solid vars.$color-gray-200;
          }
        }

        .choose-halfline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .line {
            height: 1px;
            width: 40%;
            background: vars.$color-gray-50;
          }

          .or {
            color: vars.$color-gray-100;
            font-size: 10px;
            line-height: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  @include mixins.responsive('small') {
    margin: 20px 0;
  }

  button {
    width: 200px;

    @include mixins.responsive('small') {
      width: 100%;
    }
  }

  &.confirm-payment-button {
    @include mixins.responsive('small') {
      display: none;
    }
  }

  &.button-small {
    margin-bottom: 20px;

    button {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.payment-method-wrapper {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: column;

  @include mixins.responsive('small') {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .payment-buttons {
    display: flex;
    flex-direction: column;

    label {
      max-width: 100%;
      flex-basis: 100%;
      padding: 0;

      section {
        margin: 0;
        border-radius: 12px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        height: 50px;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border: 1px solid vars.$color-gray-25;

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: vars.$color-primary-600;
          margin: 0;
        }
      }
    }
  }
}
