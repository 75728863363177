@use '../../styles/_vars.scss';

$main-opacity: .85;
$accent-opacity: .25;

/*
  these identifiers need to be global for the keyframes to be found
*/

:global .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::before {
    display: inline-block;
    content: " ";
    width: 3em;
    height: 3em;
    border: 0.375em solid black;
    border-radius: 50%;
    border-color: rgba(vars.$color-glow, $main-opacity);
    border-bottom-color: rgba(vars.$color-glow, $accent-opacity);
    margin: auto;
    animation: 1.375s linear infinite spin, 1s linear infinite pulse;
  }
  
  &--small::before {
    width: 1.5em;
    height: 1.5em;
    border-width: 0.125em;
  }

  &--large::before {
    width: 6em;
    height: 6em;
    border-width: .5em;
  }

  &--alternate::before {
    border-color: rgba(vars.$color-bg, $main-opacity);
    border-bottom-color: rgba(vars.$color-bg, $accent-opacity);
  }
}

@keyframes :global(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes :global(pulse) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .6;
  }
  100% {
    opacity: 1;
  }
}
