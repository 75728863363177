@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.credit-disclosure {
  background: #F2F8FF;
  padding: 24px;
  border-radius: 12px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  color: vars.$color-gray-500;

  @include mixins.responsive('small') {
    flex-direction: column;
  }
}