@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.detail-box {
  border-radius: 12px;
  padding: 16px;
  border: 1px solid vars.$color-gray-25;
  height: fit-content;
  background: white;
  box-shadow: 0px 1px 2px 0px #1018280d;

  .wrapper {
    padding-bottom: 10px;

    h2 {
      color: vars.$color-primary-600;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      p {
        color: vars.$color-gray-400;
        font-size: 12px;
        line-height: 18px;
      }

      &.mobile-display {
        display: none;

        @include mixins.responsive('small') {
          display: flex;
        }
      }

      &.web-display {
        @include mixins.responsive('small') {
          display: none;
        }
      }

      &.first-payment-row {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      &.balance-row {
        padding-top: 8px;
      }

      &.last-payment-row {
        padding-bottom: 8px;
      }

      .balances-column {
        color: vars.$color-gray-500;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 18px;

        &.standard-payment-amount-column {
          color: vars.$color-primary-500;

          strong {
            font-size: 18px;
            line-height: 28px;
          }
        }

        strong {
          font-size: 16px;
          line-height: 24px;
          margin-left: 5px;
        }
      }

      .right-column {
        text-align: right;
        margin-left: 8px;
      }

      h5 {
        color: vars.$color-gray-400;
        font-size: 12px;
        line-height: 18px;

        &.align-right {
          text-align: right;
        }
      }

      p {
        margin: 0;
      }

      .plan-type-tag {
        position: absolute;
        right: 0;
        padding: 2px 8px;
        border-radius: 10px;
        color: vars.$color-primary-500;
        background: vars.$color-primary-25;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        width: fit-content;
      }

      .balance {
        color: vars.$color-primary-500;
        font-weight: 600;

        span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          line-height: 18px;

          strong {
            font-size: 20px;
            line-height: 30px;
            margin-left: 5px;
          }
        }
      }
    }

    .separator {
      border-bottom: 1px solid vars.$color-gray-25;
      padding-bottom: 10px;
    }
  }

  .payment-list {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      list-style-type: none;
    }
  }
}
