@use "../../styles/vars";
@use "../../styles/mixins";

.partial-payment-page {
  padding: 30px;
}

.partial-payment {
  h3 {
    margin: .5em 0;
  }

  fieldset {
    justify-content: center;
    font-size: 1.25em;
  }

  .error-message {
    text-align: center;
    color: red;
  }
}

.exact-amount {
  display: flex;
  justify-content: center;
  margin: 1em 0;

  & > * {
    margin: 0 .5em;
  }

  input,
  button {
    font-size: 1.25em;
  }

  @include mixins.responsive('medium') {
    flex-direction: column;

    & > * {
      margin: .5em 0;
    }
  }
}
