@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.documents-page-style {
  padding: 30px;
}

.documents-list-item {
  display: flex;
  align-items: center;
  
  p {
    margin: 0 0 0.5em 0;
  }

  div {
    flex-grow: 1;
  }

  a {
    flex-grow: 0;
    margin-left: 1em;

    img {
      width: 1.25em;
      margin-right: .5em;
    }
  }

  @include mixins.responsive('medium') {
    & {
      flex-wrap: wrap;
    }

    & > * {
      flex-grow: 0;
      flex-basis: 100%;
    }

    a {
      text-align: center;
    }
  }
}

.documents-empty {
  h2 {
    margin: 1em auto;
  }

  img {
    width: 75%;
    max-width: 75vh;
  }

  text-align: center;
}
