@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.home-page {
  padding: 30px;
  background: #FBFDFF;
  position: relative;

  @include mixins.responsive('small') {
    padding: 20px;
  }

  .info-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr minmax(250px, 40%);
    margin: 20px 0;

    @include mixins.responsive('medium') {
      grid-template-columns: 100%;
    }
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: vars.$color-primary-600;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    color: vars.$color-gray-400;
  }

  .inline-cta {
    border-radius: vars.$border-radius-section;
    margin: 1em 0;
    padding: .5em 1em;
    background-color: vars.$color-secondary-g;
    text-align: center;
  }
  
  .credit-notice {
    color: vars.$color-primary-b;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    margin-top: 1em;
  }
  
  .button-row {
    margin-top: .5em;
    
    a {
      margin: .5em;
    }
  }
  
  .paid-amount {
    text-align: center;
    margin: 1em 0;
  }
  
  .payment-method-prompt {
    text-align: center;
  }
  
  .view-payment-plans {
    margin-top: 1em;
    text-align: center;
  }

  .separator {
    border-bottom: 1px solid vars.$color-gray-25;
    padding-bottom: 10px;
  }
}
