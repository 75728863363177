@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.create-plan-page {
  padding: 30px;
}

.option-list {
  a {
    @include mixins.content-section;
    cursor: pointer;
    color: vars.$color-fg;
    display: flex;
    align-items: center;

    &:hover {
      background: vars.$color-secondary-g;
    }

    margin: 1em .5em 0 .5em;
    
    @include mixins.responsive('xlarge') {
      margin: 1em 0 0 0;
    }
  }

  li {
    list-style-type: none;
  }
}

.selected-frequency {
  border: 0.125rem solid vars.$color-secondary-c;
}

.frequency-option-list {
  @include mixins.responsive-columns('xlarge', 3);

  @include mixins.responsive('xlarge') {
    max-width: 30rem;
  }

  margin-left: auto;
  margin-right: auto;

  img {
    margin-right: 1em;
    flex: 0 0 3.25em;
  }

  a {
    font-size: 1.25em;
    font-weight: bold;
  }
}

.repayment-option-list {
  @include mixins.responsive-columns('large');

  a {
    justify-content: space-between;
    position: relative;

    margin: 1em .5em 0 .5em;
    
    @include mixins.responsive('large') {
      margin: 1em 0 0 0;
    }
  }
}

.repayment-subheading {
  text-align: center;
  margin-top: 1em;
}
