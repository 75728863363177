@use '../../styles/_vars.scss';

:global .modal {
  position: fixed;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, .125);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
  dialog {
    position: relative;
    margin: auto;
    max-width: 66%;
    padding: 2.25rem 1.5rem 1.5rem 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 .125rem .125rem vars.$color-shadow;
  }

  :global .close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: none;
    font-size: 1.5em;
    padding: .5em .75em;
  }
}
