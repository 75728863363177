@use "vars";
@use "mixins";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


* {
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: none;
  vertical-align: top;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

:global .quiq-togglechatbutton-button {
  background: #0563C7 !important;
}

@media print {
  :global .quiq-floating-element {
    display: none;
  }
}

:global #app {
  position: relative;
  height: 100%;
  min-height: 100%;
  background: white;
}

:global .slide-pane.slide-pane_from_right {
  border-radius: 12px 0 0 12px;
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

/* TODO font sizes for smaller breakpoints */

body {
  background: vars.$color-body;
  color: vars.$color-fg;
}

main {
  height: 100%;

  p {
    margin: 1em 0;
  }
}

button {
  cursor: pointer;
  font-size: 1em;

  @include mixins.responsive('small') {
    font-size: 1.25em;
  }

  & > a {
    text-decoration: underline;
  }
}

h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include mixins.responsive('small') {
    font-size: 1.25rem;
  }
}

h2 {
  font-size: 2rem;

  @include mixins.responsive('small') {
    font-size: 1.125rem;
  }
}

h3 {
  font-size: 1.25rem;

  @include mixins.responsive('small') {
    font-size: 1rem;
  }
}

a {
  color: vars.$color-secondary-c;

  &[href^='tel:'],
  &[href^='mailto:'] {
    white-space: nowrap;
  }

  &:hover {
    text-decoration: underline;
  }
}

main section {
  @include mixins.content-section;
}

main {
  @include mixins.responsive('small') {
    font-size: 1em;
  }

  @include mixins.responsive('tiny') {
    font-size: 0.875em;
    
    section {
      padding: 0.75em;
      margin: .5em 0;
    }
  }
}

hr {
  border: 0.0125rem solid vars.$color-shadow;
  margin: 1em 0;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
textarea {
  border-radius: 10px;
  border: 1px solid vars.$color-active;
  padding: 10px 14px;
  box-shadow: 0px 1px 2px 0px vars.$color-input-shadow;
}