@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.empty-state {
  border: 1px solid vars.$color-gray-25;
  border-radius: 12px;
  padding: 16px 24px;
  background: white;

  .wrapper {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid vars.$color-gray-25;
    margin-bottom: 15px;
    padding-bottom: 15px;
    align-items: center;
  }

  h2 {
    font-size: 20px;
    line-height: 30px;
    color: vars.$color-primary-600;
    font-weight: 600;
  }
}

.hidden-mobile {
  display: block;

  @include mixins.responsive('medium') {
    display: none;
  }
}
