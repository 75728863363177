@use "../../styles/_vars.scss";

p {
  color: vars.$color-gray-400;
}

.account-box {
  box-shadow: none !important;
  text-shadow: none !important;
  background: white !important;
  border: 1px solid #F0F0F5 !important;
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  margin: 10px 0;
  text-align: left;

  .secondary-text {
    color: vars.$color-gray-400;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    &.small {
      font-size: 12px;
      line-height: 18px;
    }

    &.extra-info {
      margin-top: 10px;
    }
  }

  .title {
    color: vars.$color-gray-500;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}

.continue-button {
  margin-top: 15px;
}

.selected {
  border: 2px solid #0563C7 !important;
  box-shadow: 0px 12px 16px -4px #10182814 !important;
}
