@use '../../styles/_vars.scss';

.progress-bar {
  position: relative;
  height: 1em;
  background: vars.$color-secondary-g;
  box-shadow: inset 0 0.0625em 0.0625em vars.$color-shadow;

  &.new-style {
    background: vars.$color-gray-25;
    box-shadow: none;
    height: 0.5rem;
    width: 100%;

    &,
    & > div {
      border-radius: 0.25rem;
    }

    & > div {
      border-radius: 4px;
      background: var(--Primary-400, #038cfc);
      transition: width 0.2s ease-in-out;
    }
  }

  &,
  & > div {
    border-radius: 0.5em;
  }

  & > div {
    position: absolute;
    inset: 0 auto 0 0;
    background: vars.$color-secondary-a;
  }
}
