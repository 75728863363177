@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

main {
  background-color: #fbfdff;
}

.progress-bar {
  width: calc(100% - 80px);
  height: 8px;
  position: absolute;
  top: 0;
  left: 40px;

  @include mixins.responsive('small') {
    position: relative;
    left: 0;
    width: 100%;
    margin: 20px 0;
  }
}

.progress-bar::-webkit-progress-bar {
  background-color: vars.$color-gray-25;
  border-radius: 10px;
}
.progress-bar::-webkit-progress-value {
  background-color: vars.$color-primary-500;
  border-radius: 10px;
}

.wrapper {
  padding: 40px;
  position: relative;
  height: auto;
  background: #fbfdff;

  @include mixins.responsive('medium') {
    padding: 15px 20px;
  }

  .title {
    h1 {
      color: vars.$color-primary-600;
      font-size: 30px;
      line-height: 38px;
      font-weight: 600;
    }

    p {
      color: vars.$color-gray-400;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 50px;
      white-space: pre-wrap;
    }
  }
}

.payment-plans-empty {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  column-gap: 20px;
  margin-bottom: 50px;

  &:hover {
    .repayment-option {
      opacity: 70%;
    }
  }

  @include mixins.responsive('medium') {
    grid-template-columns: auto;
    grid-auto-flow: row;
    gap: 20px;

    .repayment-option {
      margin: auto;
      width: 350px;
    }
  }

  @include mixins.responsive('small') {
    display: block;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .repayment-option {
      width: 100%;
    }
  }

  .repayment-option {
    border: 1px solid vars.$color-gray-25;
    background: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    &:hover {
      opacity: 100%;
      border: 2px solid vars.$color-primary-500;
      box-shadow: 0px 20px 24px -4px #10182814;
      text-decoration: none;
      transform: scale(1, 1.03);
    }

    &:active,
    &:focus {
      border: 2px solid vars.$color-primary-500;
      box-shadow: 0px 20px 24px -4px #10182814;
    }

    h4 {
      margin: 0;
      color: vars.$color-primary-600;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    p {
      color: vars.$color-gray-400;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      white-space: break-spaces;
    }

    img {
      max-width: 130px;
    }
  }
}

.with-payment-plan {
  .next-payments {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > div {
      text-align: center;
      margin: 0.5em 0.5em;
      padding: 0em 0.5em;
      background: vars.$color-secondary-g;
      border-radius: 0.5em;

      p {
        margin: 0.5rem 0;

        &:first-child {
          font-size: 1.5em;
          line-height: 1;
          font-weight: bold;
        }
      }
    }
  }
}

.payment-stepper {
  .stepper {
    display: flex;
    gap: 10px;

    @include mixins.responsive('small') {
      display: none;
    }

    h5 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: vars.$color-gray-400;

      &.current-step {
        color: vars.$color-primary-600;
      }
    }

    margin-bottom: 20px;
  }

  .title {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: vars.$color-primary-600;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: vars.$color-gray-400;
  }

  .two-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 40% 1fr;

    @include mixins.responsive('small') {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .flex-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .dropdown-box {
    border: 1px solid vars.$color-gray-25;
    border-radius: 12px;
    background: white;
    padding: 16px;

    p {
      font-size: 16px;
      line-height: 24px;
      color: vars.$color-gray-400;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
    }

    button,
    .day-of-week-dropdown {
      width: 100%;
      justify-content: space-between;
    }

    .number-input-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;

      span {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        line-height: 20px;
        color: vars.$color-gray-500;
      }

      .number-input {
        width: 100%;
        color: vars.$color-gray-500;
        font-size: 14px;
        line-height: 20px;

        &.padded {
          padding-left: 20px;
        }

        ::placeholder {
          color: vars.$color-gray-100;
        }
      }
    }
  }

  .payment-button {
    display: flex;
    background: white;
    border: 1px solid vars.$color-gray-25;
    box-shadow: 0px 1px 2px 0px #1018280d !important;
    align-items: center;
    height: 50px;

    &.selected {
      border: 2.5px solid vars.$color-primary-600;
      box-shadow: 0px 12px 16px -4px #10182814;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: vars.$color-primary-600;
    }
  }
}

.pane-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  h2 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: vars.$color-primary-600;
  }

  button {
    padding: 0;
    height: 15px;
    width: 15px;
  }
}

.payment-info-table {
  border: 1px solid vars.$color-gray-25;
  border-radius: 12px;
  padding: 16px;
  background: white;
  margin-top: 20px;

  table {
    margin: 0;
  }
}

.add-payment-method {
  text-align: center;
}

.success-title {
  text-align: center;

  h2 {
    margin: 20px 0;
  }
}

.buttons-wrapper {
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  padding-bottom: 30px;
  border-bottom: 1px solid vars.$color-gray-25;

  @include mixins.responsive('small') {
    flex-direction: column;
  }

  button {
    width: 300px;
    border-radius: 10px;
    padding: 8px 12px;
    border: 1px solid vars.$color-gray-200;
    justify-content: center !important;
    margin: 0 !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: vars.$color-gray-500;
    display: flex;
    align-items: center;

    @include mixins.responsive('small') {
      width: 100%;
    }

    img {
      width: 20px !important;
      margin-right: 10px !important;
    }
  }

  a {
    color: vars.$color-primary-500;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}

.legal {
  p,
  a {
    color: vars.$color-gray-300;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }
}

.repayment-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 25px 0;

  &:hover {
    .select-repayment {
      opacity: 70%;
    }
  }

  @include mixins.responsive('small') {
    display: block;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  .select-repayment {
    background: white;
    border: 1px solid vars.$color-gray-25;
    text-align: left;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 12px;
    padding: 15px;

    &:hover,
    &.selected {
      opacity: 100%;
      border: 2.5px solid vars.$color-primary-500;
      transform: scale(1, 1.03);
    }

    &.blurred {
      opacity: 70%;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      color: vars.$color-gray-500;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: vars.$color-primary-600;
      text-transform: capitalize;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: vars.$color-gray-400;
      margin: 0;
      margin-top: 20px;
      font-weight: 400;
    }
  }
}

.options-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include mixins.responsive('small') {
    grid-template-columns: 1fr;
  }

  .repayment-option {
    display: grid;
    grid-template-columns: 65% 35%;
    background: white;
    border: 1px solid vars.$color-gray-25;
    border-radius: 8px;
    align-items: center;
    padding: 15px;

    .payments-tag {
      background: vars.$color-primary-25;
      color: vars.$color-primary-500;
      font-size: 12px;
      line-height: 18px;
      border-radius: 10px;
      padding: 2px 8px;
    }

    .currency-row {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-top: 5px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      color: vars.$color-primary-600;
      font-weight: 600;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: vars.$color-gray-500;
      margin-left: 5px;
    }

    button {
      font-size: 14px;
      line-height: 20px;
      height: fit-content;
      width: fit-content;
      margin: auto;
      margin-right: 0;
    }
  }
}

.selected-option-title {
  color: vars.$color-gray-400;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin: 30px 0;
}

.selected-option-subtitle {
  color: vars.$color-gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 30px 0;
}

.confirm-button {
  padding-top: 15px;

  button {
    width: 200px;
  }

  @include mixins.responsive('small') {
    display: none;
  }

  &.only-mobile {
    display: none;

    @include mixins.responsive('small') {
      display: block;
      margin: 20px 0;
    }
  }
}

.amount-to-pay-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid vars.$color-gray-25;

  span {
    font-size: 12px;
    line-height: 18px;
    color: vars.$color-gray-400;
  }

  strong {
    font-size: 20px;
    line-height: 30px;
    color: vars.$color-primary-500;
  }
}

.percentage-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  span,
  label {
    font-size: 14px;
    line-height: 20px;
    color: vars.$color-gray-500;
  }

  input:where([type='radio']) {
    width: 16px;
    height: 16px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    border: 1px solid vars.$color-gray-200;

    &:where(:checked) {
      border: 5px solid vars.$color-primary-600;
      outline: none;
    }
  }
}

.percentage-message {
  font-size: 12px;
  line-height: 18px;
  color: vars.$color-gray-400;
  margin-top: 10px;

  strong {
    font-size: 14px;
    line-height: 20px;
    color: vars.$color-primary-500;
    font-weight: 600;
  }
}

.choose-halfline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;

  .line {
    height: 1px;
    width: 45%;
    background: vars.$color-gray-50;
  }

  .or {
    color: vars.$color-gray-300;
    font-size: 12px;
    line-height: 18px;
  }
}

.side-pannel {
  @include mixins.responsive('medium') {
    width: 50% !important;
  }

  @include mixins.responsive('small') {
    width: 100% !important;
  }
}
