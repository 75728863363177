@use "../../styles/vars";

.account-select {
  width: 100%;

  label {
    a {
      margin: 0;
      padding: 0;
      color: #667085 !important;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  ul {
    width: 145px;

    li {
      a {
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }
  }
}