@use '../../styles/_vars.scss';

.link {
  font-weight: bold;
  text-decoration: underline;
  color: vars.$color-gray-300;
}

.footer-text {
  p {
    color: vars.$color-gray-300;
    font-size: 13px;
  }
}

.copy-right {
  color: vars.$color-gray-400;
}

.wrapper {
  margin-top: 30px;
}
