@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.login-page {
  height: 100%;

  .login-form {
    h1, p {
      text-align: center;
    }

    .login-input {
      label {
        color: vars.$color-gray-500;
      }
    }

    .login-button {
      background: vars.$color-button-primary;
      box-shadow: 0px 1px 2px 0px #1018280D;
      border: 1px solid vars.$color-button-primary;
      margin: 24px 0;
      font-weight: 600;
      font-size: 16px;
      padding: 12px 18px;
    }
  }

  .error {
    color: vars.$color-error-400;
  }
}
