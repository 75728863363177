@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.payment-method-select {
  @include mixins.responsive-columns('medium');
  gap: 20px;
  margin-bottom: 20px;

  & > label {
    cursor: pointer;
    padding: 0 1em;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    section {
      display: flex;

      &.selected {
        border: 2.5px solid vars.$color-primary-600;
        box-shadow: 0px 12px 16px -4px #10182814;
      }

      img {
        margin-right: 1em;
      }

      p {
        font-size: 1.25em;
      }
    }

    @include mixins.responsive('medium') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.payments {
  box-shadow: none;
  padding: 0;
  border: none;
}
