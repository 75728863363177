@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.option-list {
  @include mixins.responsive-columns('medium');
  text-align: left;

  li {
    list-style-type: none;

    & > input[type=radio] {
      display: none;

      &:checked + label {
        border-color: vars.$color-secondary-c;
      }
    }

    & > label {
      @include mixins.content-section;
      cursor: pointer;
      color: vars.$color-fg;
      border: 0.125rem solid transparent;
      align-items: center;
      margin: 1em .5em;
  
      &:hover {
        background: vars.$color-secondary-g;
      }
  
      margin: 0 .5em 1em .5em;
    }
  }
}
