@use "vars";

@mixin hide-in-print {
  @media print {
    /* this is the one time we'll permit the use of !important */
    display: none !important;
  }
}

@mixin responsive($breakpoint) {
  @if map-has-key(vars.$breakpoints, $breakpoint) {
    @media (max-width: map-get(vars.$breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn "Breakpoint must be one of: #{map-keys(vars.$breakpoints)}";
  }
}

@mixin responsive-columns($breakpoint, $num-columns: 2) {
  display: flex;
  flex-wrap: wrap;
  
  & > * {
    $calculated-width: calc(100% / $num-columns);
    flex-basis: $calculated-width;
    max-width: $calculated-width;

    @include responsive($breakpoint) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

@mixin content-section {
  background: vars.$color-bg;
  border-radius: vars.$border-radius-section;
  box-shadow: 0 .125em .25em vars.$color-shadow;
  margin: 1em 0;
  padding: 1.5rem;
  display: block;
}

@mixin detail-columns($breakpoint: 'medium') {
  & > * {
    display: flex;
    justify-content: space-between;

    & > *:first-child {
      text-align: left;
      flex-grow: 0;
      flex-shrink: 0;
      font-weight: bold;
    }

    & > *:last-child {
      text-align: right;
      flex-grow: 1;
    }

    @include responsive($breakpoint) {
      display: block;

      & > *:first-child {
        display: block;
      }

      & > *:last-child {
        display: block;
      }
    }
  }
}
