@use '../../styles/_vars.scss';
@use '../../styles/_mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eaecf0;
  height: 80px;
  background: white;

  div {
    background: none;
  }

  .menu {
    display: none;
  }

  .logo {
    width: 245px;
  }

  button {
    display: none;
  }

  .mobile-logo {
    display: none;
  }

  @include mixins.responsive('medium') {
    .logo {
      display: none;
    }

    .link {
      display: none;
    }

    .tablet-logo {
      display: block;
    }

    .tabletLink {
      display: block;
    }

    .menu {
      display: block;
    }

    .toggled {
      display: block;
    }

    button {
      display: block;
      background: none;
      box-shadow: none !important;
      border: none;
    }
  }

  @include mixins.responsive('small') {
    .tablet-logo {
      display: none;
    }

    .tabletLink {
      display: none;
    }

    .mobile-logo {
      display: block;
    }
  }
}
