@use '../../styles/_vars.scss';
.accordion {
  border-radius: 4px;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: vars.$color-primary-600;
    cursor: pointer;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: transform 0.3s ease;
      }
    }

    .arrow-open {
      svg {
        transition: transform 0.3s ease;
        transform: rotateX(180deg);
      }
    }
  }

  .accordion-content {
    padding: 10px 0 0;
    color: vars.$color-gray-400;
    font-size: 12px;
    line-height: 18px;
  }
}
