@use '../../styles/_mixins.scss';

iframe {
  width: 100%;

  @include mixins.responsive('small') {
    max-height: 1220px;
  }
}

.disclosure {
  color: black;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 50px;
}
